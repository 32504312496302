.approach-container {
    padding: 2em;
    text-align: center;
}

.approach-container h1 {
    margin-bottom: 1em;
}

.approach-container p {
    margin-bottom: 2em;
}

.approach-section {
    background: #fefcfb;
    padding: 1.5em;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-bottom: 1em;
    text-align: left;
}

.approach-section h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
}

.approach-section p {
    margin-bottom: 0;
}

.technology-container {
    padding: 2em;
    background: #f9f9f9;
}

.technology-content {
    display: flex;
    align-items: flex-start;
    gap: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.technology-text {
    flex: 1;
    color: #0A1128; 
    max-width: 400px;
}

.technology-text h1 {
    margin-bottom: 1em;
    font-size: 2rem;
}

.technology-text p {
    margin-bottom: 1em;
    font-weight: 700;
    padding: 0;
}

.technology-images {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.technology-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    max-width: 100%;
}

.technology-grid > div { /* Adjust this selector to match your grid item */
    flex: 1 1 calc(20% - 1em); /* 5 items per row on desktop */
    max-width: 200px;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
    .technology-content {
        flex-direction: column;
    }

    .technology-item {
        flex: 1 1 calc(50% - 1em); /* 2 items per row on mobile */
    }
}

.technology-item {
    background: #fefcfb;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.technology-image {
    max-width: 100px;
    height: auto;
    margin-bottom: 1em;
}

.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f9f9f9;
    padding: 1em;
}

.contact-container {
    width: 40%;
    margin: 2% auto;
    padding: 2em;
    background: #fefcfb;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: left;
}

.contact-container h1 {
    text-align: center;
    margin-bottom: 1em;
    font-size: 2rem;
}

.contact-container label {
    display: block;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    font-weight: bold;
}

.contact-container input,
.contact-container textarea,
.contact-container .contact-us-fi .form-control {
    width: 100%;
    padding: 0.5em 0;
    margin-bottom: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-container button {
    display: block;
    width: 100%;
    padding: 0.75em;
    border: none;
    border-radius: 4px;
    background: #0A1128;
    color: #fefcfb;
    font-size: 1em;
    cursor: pointer;
}

.contact-container button:hover {
    background: #555;
}
.contact-container .contact-us-fi input[type="tel"] {
    padding-left: 6%;
    border-radius: 0 4px 4px 0;
}


@media (max-width: 768px) {
    .contact-container {
        width: 85%;
        margin: 0 auto;
        padding: 2rem 0;
        background: #fefcfb;
        border-radius: 8px;
        box-shadow: none;
        text-align: left;
    }
    
    .contact-container .contact-us-fi input[type="tel"] {
        padding-left: 17%;
        border-radius: 0 4px 4px 0;
    }
}
h1 {
    font-size: 4.5rem;
    padding: 0;
    margin: 0;
}

h2 {
    padding-top: 0;
    margin-top: 0;
    font-size: 2.3rem;
}

p {
    font-size: 1.3rem;
    padding: 0.5% 20%;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    h1 {
        font-size: 3rem; /* Adjusted for smaller screens */
    }

    h2 {
        font-size: 1.8rem; /* Adjusted for smaller screens */
    }

    p {
        font-size: 1rem; /* Adjusted for smaller screens */
        padding: 0.5% 10%; /* Reduced padding on smaller screens */
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 2.5rem; /* Further adjustment for very small screens */
    }

    h2 {
        font-size: 1.5rem; /* Further adjustment for very small screens */
    }

    p {
        font-size: 0.9rem; /* Further adjustment for very small screens */
        padding: 0.5% 5%; /* Further reduced padding for very small screens */
    }
}

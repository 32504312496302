.footer {
    background: #0A1128;
    color: #fefcfb;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-contact,
.footer-social {
    flex: 1 1 200px;
    margin: 1em;
}

.footer-contact h2,
.footer-social h2 {
    margin-bottom: 0.5em;
    font-size: 2rem;
    font-weight: 400;
}

.footer-contact p {
    margin: 1em 0;
    font-size: 1rem;
}

.footer-social ul {
    list-style: none;
    padding: 0;
}

.footer-social ul li {
    margin: 1em 0;
    font-size: 1rem;
}

.footer-social ul li a {
    color: #fefcfb;
    text-decoration: none;
}

.footer-social ul li a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .footer-contact h2,
.footer-social h2 {
    font-size: 1.3rem;
}
.footer-contact p {
    font-size: 0.8rem;
}
.footer-social ul li {
    font-size: 0.8rem;
}
}
/* Navbar Styles */
.navbar {
    display: flex;
    align-items: center;
    background: #0A1128;
    color: white;
    padding: 1em;
}

.navbar-logo img {
    height: 40px;
    margin-right: 1em;
}

.navbar-links {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.navbar-links li {
    margin: 0 1em;
}

.navbar-links li a {
    color: white;
    text-decoration: none;
}

.navbar-links li a:hover {
    text-decoration: underline;
}

/* Burger Menu Icon */
.burger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.burger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px;
}

/* Media Queries */
@media (max-width: 768px) {
    .navbar-links {
        display: none;
        position: absolute;
        top: 70px;
        right: 0;
        background: #0A1128;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .navbar{
    justify-content: space-between;
    }
    .navbar-links.active {
        display: flex;
    }

    .navbar-links li {
        margin: 0.5em 0; /* Adds a small separation between the items */
    }

    .burger {
        display: flex;
    }
}
